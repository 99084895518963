



























































































import { Component, Vue } from 'vue-property-decorator';
import { getComponent,getView } from '@/utils/helpers';
import workspaceModule from '@/store/modules/workspaceModule';
import workflowModule from '@/store/modules/workflowModule';
import scheduleModule from '@/store/modules/scheduleModule';
import schedule from '@/router/routes/schedule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    PersonnelSchedulerNavbar: () => getView('PersonnelSchedulerNavbar'),
	PersonnelScheduler: () => getView('PersonnelScheduler'),
	PersonnelSchedulerGantt: () => getView('PersonnelSchedulerGantt'),
	Calendar: () => getView('Calendar'),
	WellTestSchedule: () => getView('WellTestSchedule'),
	UserSettings: () => getComponent('common/UserSettings'),
	PersonnelScheduleAddScheduleModal: () => getComponent('common/PersonnelScheduleAddScheduleModal'),
	PersonnelScheduleAddAssetRowModal: () => getComponent('common/PersonnelScheduleAddAssetRowModal'),
  },
})
export default class PersonnelScheduler extends Vue {

  loadingTasqs = false

  addNewTablePopup = false

  addNewRowPopup = false

  workflowAutomationDialog = true

  viewSelected = 'Schedule'

  didSelectView(view) {
	  this.viewSelected = view
  }

  get activeWorkspace() {
    return scheduleModule.activePersonnelWorkspace;
  }

  get activeSchedule() {
    return scheduleModule.activePersonnelSchedule;
  }




  created() {
	  this.resetPage()
	
  }

  async resetPage() {
    this.loadingTasqs = true;
	workspaceModule.setActivePage(null)
	workspaceModule.resetAllData()
	await scheduleModule.getPersonnelWorkspaceSched({})
    this.loadingTasqs = false;
  }




  async addNewAssetRow(asset_type, asset) {

	scheduleModule.setIsSavingAddNewSchedule(true)
	await scheduleModule.postNewShiftRow({
		workspace_id: scheduleModule.activePersonnelWorkspace.ID,
		schedule_id: scheduleModule.activePersonnelSchedule.ID,
		asset_type: asset_type,
		asset: asset
	})

	scheduleModule.setIsSavingAddNewSchedule(false)

	this.closeAddNewRowPopup()
	this.resetPage()
  }


  async addNewSchedule(role) {

	scheduleModule.setIsSavingAddNewSchedule(true)
	await scheduleModule.postNewPersonnelSchedule({
		workspace_id: scheduleModule.activePersonnelWorkspace.ID,
		role: role
	})
	scheduleModule.setRecentlyPostedNewScheduleID(null)
	scheduleModule.setIsSavingAddNewSchedule(false)

	this.closeAddNewTablePopup()
	this.resetPage()
  }

  closeAddNewTablePopup() {
	  this.addNewTablePopup = false
  }

  closeAddNewRowPopup() {
	  this.addNewRowPopup = false
  }

  addNewTable() {
	  this.addNewTablePopup = true
  }


  addNewRow() {
	  this.addNewRowPopup = true
  }




}
